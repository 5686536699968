































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { getComponent, jsonParse, sleep } from "@/utils/helpers";
import accountModule from "@/store/modules/accountModule";
import assetsModule from "@/store/modules/assetsModule";
import scheduleModule from "@/store/modules/scheduleModule";
import { mixins } from "vue-class-component";
import Multiselect from "vue-multiselect";
import workspaceModule from "@/store/modules/workspaceModule";
import { v4 as uuidv4 } from 'uuid';
import schedule from "@/router/routes/schedule";
@Component({
  components: {
    Multiselect,
  },
})
export default class ScheduleV2 extends mixins() {
  selectedColumnType = "DETAILS";
  userSelectionVal = "";
  yourValue = "";

  // NEEDED
  refreshResponseDataKey = 0;
  addShiftChecklistPopup = false;
  addShiftIsLoading = false;

  // NEEDED
  addShiftGroupSelected = "";

  // Loading views
  dataLoading = false;
  popupBackground = false;
  selectedDayOfWeek: any[] =[];
  daysOfWeek = [["Tue", "Tuesday"], ["Wed", "Wednesday"], ["Thu", "Thursday"], ["Fri", "Friday"], ["Sat", "Saturday"]];
  selectedTemplate = -1
  templateOptions: any[] = [];
  timeOptions: any[] = [];
  dayOptions: any[] = [];
  shiftEndTimeOptions: any[] = ["00:00"];
  recurEndTimeOptions: any[] = ["00:00"];
  userOptions: any[] = [];
  assetOptions: any[] = [];
  assetType = "";
  shiftDate = "";
  errorMessages = { shiftDate: "", recurStartDate: "", recurEndDate: "", weekdays: "", assetType: "", assetName: "", username: "" }
  dataErrorMessage = "";
  userName = "";
  assetName = "";
  shiftTemplates: any;
  allAssetTypes: any;
  shiftStartTime='';
  shiftEndTime='';
  recurEndDate='';
  recurType='';
  onDays=1;
  offDays=1;
  allDay = false;
  disableShiftStartTime = false;
  disableShiftEndTime = false;

  isSavingShift = false

  tests() {
	  console.log(this.assetName)
  }

  get activeComponent() {
    return scheduleModule.activeComponent;
  }

  get activeColumns() {
    return scheduleModule.activeComponentResponseColumns;
  }

  get scheduledRowComponents(): any[] {
      return scheduleModule.scheduledRowComponents
  }

  get shiftRowDetails() {
    return scheduleModule.addShiftRowDetails
  }

  changeAllDay(){
    this.allDay = !(this.allDay)
    if (this.allDay == false) {
      this.disableShiftStartTime = false;
      this.disableShiftEndTime = false;
    } else {
      this.disableShiftStartTime = true;
      this.disableShiftEndTime = true;
    }
  }

  setTimeOptions() {
	function pad(n, width, z) {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	}
    for (let i = 0; i <= 23; i++) {
      for (let j = 0; j < 60; j+=15) {

		  if (i == 0) {
			  if (j == 0) {
				  this.timeOptions.push(`12:00am`);
			  } else {
				  this.timeOptions.push(`12:${pad(j, 2, '0')}am`);
			  }
			  
		  } else if (i < 12) {
			  if (j == 0) {
				  this.timeOptions.push(`${i}:00am`);
			  } else {
				  this.timeOptions.push(`${i}:${pad(j, 2, '0')}am`);
			  }
		  } else if (i == 12) {
			  if (j == 0) {
				  this.timeOptions.push(`${i}:00pm`);
			  } else {
				  this.timeOptions.push(`${i}:${pad(j, 2, '0')}pm`);
			  }
		  } else {
			  if (j == 0) {
				  this.timeOptions.push(`${i - 12}:00pm`);
			  } else {
				  this.timeOptions.push(`${i - 12}:${pad(j, 2, '0')}pm`);
			  }
		  }
      }
    }
    this.shiftStartTime='12:00am';
    this.setShiftEndTimeOptions(this.shiftStartTime);
    this.shiftEndTime='11:45pm';
  }

  setDayOptions() {
    for (let i = 1; i <= 30; i++) {
      this.dayOptions.push(i);
    }
  }

  setShiftEndTimeOptions(value) {
    if (typeof value == "string" && value.constructor == String) {
      this.shiftEndTimeOptions = this.timeOptions.slice();
    //   for (let i = 0; i <=this.timeOptions.indexOf(value); i++) {
    //     this.shiftEndTimeOptions.shift();
    //   }
    //   this.shiftEndTime = this.shiftEndTimeOptions[0];
    }
  }

  setRecurEndTimeOptions(value) {
    if (typeof value == "string" && value.constructor == String) {
      this.recurEndTimeOptions = this.timeOptions.slice();
    //   for (let i = 0; i <=this.timeOptions.indexOf(value); i++) {
    //     this.recurEndTimeOptions.shift();
    //   }
    }
  }

  clickDayOfWeek(value) {
    
    if (this.selectedDayOfWeek.indexOf(value) === -1) {
      this.selectedDayOfWeek.push(value);
    } else {
      this.selectedDayOfWeek.splice(this.selectedDayOfWeek.indexOf(value),1)
    }
  }

  // NEEDED
  closeAddShiftPopup() {
    this.$emit("close-add-shift-popup");
  }




	dateRangeOverlaps(a_start, a_end, b_start, b_end) {
		if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
		if (a_start <= b_end   && b_end   <= a_end) return true; // b ends in a
		if (b_start <  a_start && a_end   <  b_end) return true; // a in b
		return false;
	}



  timeToMilitaryTimeString(time) {

    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

	  var hour = time.split(":")[0]
	  var minutes = time.split(":")[1]
	  minutes = minutes.replace("am", "")
	  minutes = minutes.replace("pm", "")
	  var meridian = time.includes("am") ? "AM" : "PM"
	  if (meridian == "AM" && hour == 12) {
		  return pad(0, 2, '0') + ":" + pad(minutes, 2, '0')
	  } else if (meridian == "AM") {
		  return pad(hour, 2, '0') + ":" + pad(minutes, 2, '0')
	  } else if (meridian == "PM" && hour == 12) {
		  return pad(12, 2, '0') + ":" + pad(minutes, 2, '0')
	  } else {
		  return pad(parseInt(hour) + 12, 2, '0') + ":" + pad(minutes, 2, '0')
	  }
  }
  


  async addShiftSave() {
    // @ts-ignore
	this.isSavingShift = true
	await sleep(400)
    var errorFlag = false;
    for (var k in this.errorMessages) {
      this.errorMessages[k] = "";
    }
    if (this.shiftDate == "") {
      errorFlag = true;
      this.errorMessages.shiftDate = "Please select a date";
    }
    if (this.recurType != "") {

		var six_months_later = new Date()
		var newDate = new Date(six_months_later.setMonth(six_months_later.getMonth()+6));
		if ((new Date(this.recurEndDate)).getTime() > newDate.getTime() && this.recurType != "") {
			errorFlag = true;
			this.errorMessages.weekdays = "Please select a range less than 6 months";
			this.isSavingShift = false
			return
		}


      if (this.shiftDate == "") {
        errorFlag = true;
        this.errorMessages.shiftDate = "Please select start date";
      }
      if (this.recurEndDate == "") {
        errorFlag = true;
        this.errorMessages.recurEndDate = "Please select end date";
      }
      if (this.selectedDayOfWeek.length == 0 && this.recurType != "ON_OFF") {
        errorFlag = true;
        this.errorMessages.weekdays = "Please select weekdays";
      }
    }
    if (this.assetType == '') {
      errorFlag = true;
      this.errorMessages.assetType = "Please select asset type"
    }

    if (this.assetName == '') {
      errorFlag = true;
      this.errorMessages.assetName = "Please select asset name"
    }


    if (errorFlag) {
		this.isSavingShift = false
      return;
	}


    var startTime, endTime, shiftTemplateID;
    if (this.selectedTemplate >= 0) {
      startTime = this.shiftTemplates[this.selectedTemplate].StartTime;
      endTime = this.shiftTemplates[this.selectedTemplate].EndTime;
      scheduleModule.addPersonnelScheduleShiftTemplates({
        id: this.shiftTemplates[this.selectedTemplate].ID,
        name: this.shiftTemplates[this.selectedTemplate].TemplateName,
        color: this.shiftTemplates[this.selectedTemplate].Color,
      })
	  shiftTemplateID = this.shiftTemplates[this.selectedTemplate].ID
    } else {
      if (this.allDay) {
		  var offset = (new Date().getTimezoneOffset()) / 60;
        startTime = "00:00";
        endTime = "23:59";
      } else {
        startTime = this.timeToMilitaryTimeString(this.shiftStartTime);
        endTime = this.timeToMilitaryTimeString(this.shiftEndTime);
      }
    }



	// var localShiftRowDetails = this.shiftRowDetails
	// for (var x = 0; x < scheduleModule.totalScheduledRowComponents.length; x++) {
	// 	for (var y = 0; y < scheduleModule.totalScheduledRowComponents[x].rows.length; y++) {
	// 		if (this.assetName == scheduleModule.totalScheduledRowComponents[x].rows[y].title_row.selection_name) {
	// 			localShiftRowDetails = scheduleModule.totalScheduledRowComponents[x].rows[y]
	// 		}
	// 	}
		
	// }
	
    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

	// If this is a newly selected well, then subtract times by the timezone, and if the start date is less than zero, subract a day from the datetime
	// Also, if the recurType exists, then loop through and add several shifts that recur
	// Figure out how to compbine total existing and new/edited conponents, and how to display them, maybe setup new inner rows

	var offset = (new Date().getTimezoneOffset()) / 60;
	startTime = new Date(this.shiftDate + "T" + startTime + ":00")
	endTime = new Date(this.shiftDate + "T" + endTime + ":00")
	startTime.setTime(startTime.getTime() + (offset*60*60*1000));
	endTime.setTime(endTime.getTime() + (offset*60*60*1000));


	var start_datetime_string = startTime.getFullYear() + "-" + pad(startTime.getMonth() + 1, 2, '0') + "-" + pad(startTime.getDate(), 2, '0') + "T" + pad(startTime.getHours(), 2, '0') + ":" + pad(startTime.getMinutes(), 2, '0')+ ":" + pad(startTime.getSeconds(), 2, '0');
	var end_datetime_string = endTime.getFullYear() + "-" + pad(endTime.getMonth() + 1, 2, '0') + "-" + pad(endTime.getDate(), 2, '0') + "T" + pad(endTime.getHours(), 2, '0') + ":" + pad(endTime.getMinutes(), 2, '0')+ ":" + pad(endTime.getSeconds(), 2, '0');
	var shift_results = {
      type: 'new',
      date: this.shiftDate,
      username: this.userName == "" ? null : this.userName,
      startTime: start_datetime_string,
      endTime: end_datetime_string,
	  assetType: this.assetType,
	  asset: this.assetName,
      recurType: this.recurType,
      recurStartDate: this.shiftDate.slice(0,19), // "2022-04-01T01:00:00"
      recurEndDate: this.recurEndDate.slice(0,19), // "2022-04-05T01:00:00"
      weekdays: JSON.stringify(this.selectedDayOfWeek),
      onDays: this.onDays,
      offDays: this.offDays,
      workspace_id: scheduleModule.activePersonnelWorkspace.ID,
	  schedule_id: scheduleModule.activePersonnelSchedule.ID,
      selection_id: this.assetName,
      RowID: this.assetName,
	  shiftTemplateID: shiftTemplateID
    }

	var start_date = shift_results.startTime
	var end_date = shift_results.endTime

	var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
	if (this.recurType == "ON_OFF") {

		var recurStartDateObj = new Date(shift_results.recurStartDate)
		var recurEndDateObj = new Date(shift_results.recurEndDate)

		recurStartDateObj.setTime(recurStartDateObj.getTime() + (offset*60*60*1000));
		recurEndDateObj.setTime(recurEndDateObj.getTime() + (offset*60*60*1000));


		var count = 0
		var total_days = parseInt(shift_results.onDays.toString()) + parseInt(shift_results.offDays.toString())
		for (var d = new Date(recurStartDateObj.getTime()); d <= recurEndDateObj; d.setDate(d.getDate() + 1)) {
			if (count < shift_results.onDays) {
				var checkRecurEndDateObj = new Date(d.getTime())
				checkRecurEndDateObj.setDate(checkRecurEndDateObj.getDate() + 1)

				var local_component = {
					Asset: this.assetName,
					AssetType: this.assetType,
					Color: "#ffffff",
					EndTime: checkRecurEndDateObj.getFullYear() + "-" + pad(checkRecurEndDateObj.getMonth() + 1, 2, '0') + "-" + pad(checkRecurEndDateObj.getDate(), 2, '0') + "T" + pad((new Date(shift_results.endTime)).getHours(), 2, '0') + ':' +  pad((new Date(shift_results.endTime)).getMinutes(), 2, '0') + ":00",
					ID: uuidv4(),
					Level: this.assetType,
					RowID: this.assetName,
					ScheduleID: scheduleModule.activePersonnelSchedule.ID,
					StartTime: d.getFullYear() + "-" + pad(d.getMonth() + 1, 2, '0') + "-" + pad(d.getDate(), 2, '0') + "T" +  pad((new Date(shift_results.startTime)).getHours(), 2, '0') + ':' +  pad((new Date(shift_results.startTime)).getMinutes(), 2, '0') + ":00",
					TemplateID: shiftTemplateID,
					Username: this.userName == "" ? null : this.userName,
					WorkspaceID: scheduleModule.activePersonnelWorkspace.ID,
					type: "new",
					changed: true
				}
	
				scheduleModule.addFilterScheduledComponent({data: local_component})


				// scheduleModule.addFilterScheduledComponent({data: local_component})



				// var data = {
				// 	assignee: {
				// 		selected: true,
				// 		selection_id: shift_results.username,
				// 		selection_name: shift_results.username
				// 	},
				// 	component_id: uuidv4(),
				// 	end_date: checkRecurEndDateObj.getFullYear() + "-" + pad(checkRecurEndDateObj.getMonth() + 1, 2, '0') + "-" + pad(checkRecurEndDateObj.getDate(), 2, '0') + "T" + pad((new Date(shift_results.endTime)).getHours(), 2, '0') + ':' +  pad((new Date(shift_results.endTime)).getMinutes(), 2, '0') + ":00",
				// 	start_date: d.getFullYear() + "-" + pad(d.getMonth() + 1, 2, '0') + "-" + pad(d.getDate(), 2, '0') + "T" +  pad((new Date(shift_results.startTime)).getHours(), 2, '0') + ':' +  pad((new Date(shift_results.startTime)).getMinutes(), 2, '0') + ":00",
				// 	template_id: shift_results.shiftTemplateID,
				// 	title_row: {
				// 		selected: true,
				// 		selection_id: shift_results.selection_id,
				// 		selection_name: shift_results.asset
				// 	},
				// 	well: shift_results.asset,
				// 	is_from_edit: shift_results,
				// 	changed: true,
				// 	type: "new"
				// }
				// scheduleModule.addFilterScheduledComponent({data: data, filter_index: 0, row_index: 0})
			}
			if ((count + 1) >= total_days) {
				count = 0
			} else {
				count = count + 1
			}
			
		}

	} else if (this.recurType == "WEEKDAYS") {
		var recurStartDateObj = new Date(shift_results.recurStartDate)
		var recurEndDateObj = new Date(shift_results.recurEndDate)
		var count = 0
		for (var d = new Date(recurStartDateObj.getTime()); d <= recurEndDateObj; d.setDate(d.getDate() + 1)) {
			// var checkRecurEndDateObj = new Date(recurEndDateObj.getTime())
			// checkRecurEndDateObj.setDate(checkRecurEndDateObj.getDate() + count)

			var day = days[ d.getDay() ];
			if (this.selectedDayOfWeek.indexOf(day) > -1) {
				var checkRecurEndDateObj = new Date(d.getTime())
				checkRecurEndDateObj.setDate(checkRecurEndDateObj.getDate() + 1)



				var local_component = {
					Asset: this.assetName,
					AssetType: this.assetType,
					Color: "#ffffff",
					EndTime: checkRecurEndDateObj.getFullYear() + "-" + pad(checkRecurEndDateObj.getMonth() + 1, 2, '0') + "-" + pad(checkRecurEndDateObj.getDate(), 2, '0') + "T" + pad((new Date(shift_results.endTime)).getHours(), 2, '0') + ':' +  pad((new Date(shift_results.endTime)).getMinutes(), 2, '0') + ":00",
					ID: uuidv4(),
					Level: this.assetType,
					RowID: this.assetName,
					ScheduleID: scheduleModule.activePersonnelSchedule.ID,
					StartTime: d.getFullYear() + "-" + pad(d.getMonth() + 1, 2, '0') + "-" + pad(d.getDate(), 2, '0') + "T" +  pad((new Date(shift_results.startTime)).getHours(), 2, '0') + ':' +  pad((new Date(shift_results.startTime)).getMinutes(), 2, '0') + ":00",
					TemplateID: shiftTemplateID,
					Username: this.userName == "" ? null : this.userName,
					WorkspaceID: scheduleModule.activePersonnelWorkspace.ID,
					type: "new",
					changed: true
				}
	
				scheduleModule.addFilterScheduledComponent({data: local_component})





				// var data = {
				// 	assignee: {
				// 		selected: true,
				// 		selection_id: shift_results.username,
				// 		selection_name: shift_results.username
				// 	},
				// 	component_id: uuidv4(),
				// 	end_date: checkRecurEndDateObj.getFullYear() + "-" + pad(checkRecurEndDateObj.getMonth() + 1, 2, '0') + "-" + pad(checkRecurEndDateObj.getDate(), 2, '0') + "T" + pad((new Date(shift_results.endTime)).getHours(), 2, '0') + ':' +  pad((new Date(shift_results.endTime)).getMinutes(), 2, '0') + ":00",
				// 	start_date: d.getFullYear() + "-" + pad(d.getMonth() + 1, 2, '0') + "-" + pad(d.getDate(), 2, '0') + "T" +  pad((new Date(shift_results.startTime)).getHours(), 2, '0') + ':' +  pad((new Date(shift_results.startTime)).getMinutes(), 2, '0') + ":00",
				// 	template_id: shift_results.shiftTemplateID,
				// 	title_row: {
				// 		selected: true,
				// 		selection_id: shift_results.selection_id,
				// 		selection_name: shift_results.asset
				// 	},
				// 	well: shift_results.asset,
				// 	is_from_edit: shift_results,
				// 	changed: true,
				// 	type: "new"
				// }
				// scheduleModule.addFilterScheduledComponent({data: data, filter_index: 0, row_index: 0})
			}
			count = count + 1
		}


	} else {

	// var start_date = shift_results.startTime
	// var end_date = shift_results.endTime

		var local_component = {
			Asset: this.assetName,
			AssetType: this.assetType,
			Color: "#ffffff",
			EndTime: end_date,
			ID: uuidv4(),
			Level: this.assetType,
			RowID: this.assetName,
			ScheduleID: scheduleModule.activePersonnelSchedule.ID,
			StartTime: start_date,
			TemplateID: shiftTemplateID,
			Username: this.userName == "" ? null : this.userName,
			WorkspaceID: scheduleModule.activePersonnelWorkspace.ID,
			type: "new",
			changed: true
		}

		scheduleModule.addFilterScheduledComponent({data: local_component})



		// var data = {
		// 	assignee: {
		// 		selected: true,
		// 		selection_id: shift_results.username,
		// 		selection_name: shift_results.username
		// 	},
		// 	component_id: uuidv4(),
		// 	end_date: end_date,
		// 	start_date: start_date,
		// 	template_id: shift_results.shiftTemplateID,
		// 	title_row: {
		// 		selected: true,
		// 		selection_id: shift_results.selection_id,
		// 		selection_name: shift_results.asset
		// 	},
		// 	well: shift_results.asset,
		// 	is_from_edit: shift_results,
		// 	changed: true,
		// 	type: "new"
		// }
		// scheduleModule.addFilterScheduledComponent({data: data, filter_index: 0, row_index: 0})
	}
	
	

    // @ts-ignore
    this.closeAddShiftPopup();
	this.isSavingShift = false
  }

  addShiftResponseData(addShiftResponseData: any) {
    throw new Error("Method not implemented.");
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }



  async setUserOptions(){
	// var nonSortedOptions = scheduleModule.accountDetails.map(item=>{
    //   return { value: item.email, text: item.name }
    // })
	// console.log()
    this.userOptions.push(scheduleModule.accountDetails.map(item=>{
      return { value: item.email, text: item.name }
    })) 
	// console.log(this.userOptions)

	this.userOptions[0].sort(function(a, b) {
			var textA = a.text.toUpperCase();
			var textB = b.text.toUpperCase();
			return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
		});


  }
  async getAllAssetOptions(){
    var detailedComponent, currentComponent;
	await scheduleModule.getOperatorAssets();

    this.allAssetTypes = {
      "Area": JSON.parse(scheduleModule.operatorAssetsOutput.Areas),
      "Pad": JSON.parse(scheduleModule.operatorAssetsOutput.Pads),
      "Well": JSON.parse(scheduleModule.operatorAssetsOutput.Wells),
      "Route": JSON.parse(scheduleModule.operatorAssetsOutput.Routes)
    }


    // for (var x = 0; x < scheduleModule.totalComponents.length; x++) {
    // //   detailedComponent = this.getFullComponentDetailsByID(scheduleModule.totalComponents[x].ID)
    // //   if (detailedComponent != null) {




    //     if (this.shiftRowDetails && this.shiftRowDetails.title_row.selection_id == detailedComponent.ID)
    //       currentComponent = detailedComponent;
	// 	// }
	//   }
	  
    if (currentComponent) {
      this.assetType = currentComponent.AssetType
      this.setAssetOptions()
      this.assetName = currentComponent.Asset
    } 
	else if (scheduleModule.addShiftRowDetails != null) {
		
		this.assetType = scheduleModule.addShiftRowDetails.level.charAt(0).toUpperCase() + scheduleModule.addShiftRowDetails.level.slice(1);
		this.setAssetOptions()
		await sleep(100)
		this.assetName = scheduleModule.addShiftRowDetails.asset_name
		
	}
  }


  getDropdownTimeString(time) {
	function pad(n, width, z) {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	}
	var hour_string = parseInt(time.split(":")[0])
	var minute_string = parseInt(time.split(":")[1])
	  if (hour_string == 0) {
		  return `12:${pad(minute_string, 2, '0')}am`
	  } else if (hour_string < 12) {
		return `${hour_string}:${pad(minute_string, 2, '0')}am`
	  } else if (hour_string == 12) {
		return `12:${pad(minute_string, 2, '0')}pm`
	  } else if (hour_string <= 23) {
		  return `${hour_string - 12}:${pad(minute_string, 2, '0')}pm`
	  }
	  return ''
  }



  async getShiftTemplates(){
    this.templateOptions = [{ value: -1, text: 'Custom' }];
    this.shiftTemplates = await scheduleModule.getShiftTemplates();
    if (this.shiftTemplates) {
      for (let i=0; i<this.shiftTemplates.length; i++) {
		  
        this.templateOptions.push({ value: i, text: this.shiftTemplates[i].TemplateName + " (" + this.getDropdownTimeString(this.shiftTemplates[i].StartTime) + "-" + this.getDropdownTimeString(this.shiftTemplates[i].EndTime) + ")" })
      }
    }
  }
  async setAssetOptions(){

    if (this.allAssetTypes) {
      this.assetName = ''
      this.assetOptions = this.allAssetTypes[`${this.assetType}`]
    }
  }

  async created() {
    this.setTimeOptions(), 
    this.setDayOptions(),
    this.setUserOptions(),
    this.getShiftTemplates();
	await this.getAllAssetOptions();
  }
}
